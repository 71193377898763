import MainLayout from "../layouts/MainLayout"
import {Helmet} from "react-helmet";

function MainPage() {
  return (
    <MainLayout>

      <Helmet>
        <title>Школа борьбы для детей в Бутово - Superstar</title>
        <meta name="description" content="Школа борьбы в Бутово. Первое занятие бесплатно" />
        <meta name="keywords" content="самбо,дзюдо,борьба,бутово,школа" />
      </Helmet>

      <section className="banner-area banner-bg">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 offset-xl-3 col-lg-7 offset-lg-2">
              <div className="banner-text">
                <h1 className="heading">Записать ребенка<br/>
                  на борьбу - самбо и дзюдо
                </h1>
                <div className="banner-btn text-center ">
                  <a href="https://wa.me/79686093866" target="_blank"  rel="noreferrer" className="template-btn">записаться</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-area section-padding" id="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 align-self-center mb-5 mb-lg-0">
              <h2>О школе</h2>
              <p className="mt-5">Школа борьбы superstar уже более 6 лет обучает детей мастерству таких единоборств как самбо и дзюдо, а также
                уделяет не мало внимания общей физической подготовке.
              </p><br/>
              <p>Ежедневно профессиональный тренерский состав знакомит учеников школы с разными акспектами
                единоборств, что укрепляет дух, воспитывает характер и развивают чувство уверенности в себе.
              </p><br/>
              <p>Получить травму в детской секции практически невозможно.
                Здесь не отрабатывают травмоопасных приемов, а за ходом соревновательных схваток каждую минуту следит тренер, вовремя реагирующий на нарушения.</p>
            </div>
            <div className="col-lg-6 p-0 m-0 row justify-content-center">
              <img className="img-fluid" src={require("../assets/images/about.png")} alt="цель и задача"/>
            </div>
          </div>
        </div>
      </section>

      <section className="friend-area section-padding text-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 heading">
              <h2>Первое занятие бесплатно</h2>
              <h4 className="pt-3 pb-4">Попробуйте привести ребенка и  убедиться<br />в качестве нашей школы</h4>
              <div className="banner-btn text-center ">
                <a href="https://wa.me/79686093866" target="_blank"  rel="noreferrer" className="template-btn">записаться</a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="feature-area section-padding" id="trainers">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-top text-center">
                <h2>Тренеры</h2>
              </div>
            </div>

          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="single-feature mb-5 mb-lg-0">
                <div className="feature-img">
                  <img src={require("../assets/images/coaches1.jpg")} alt="Тренер Шумейко Михаил Юрьевич"/>
                </div>
                <div className="feature-footer text-center">
                  <h5>Шумейко Михаил Юрьевич</h5>
                  <p>Тренер по самбо и дзюдо<br/>
                    Мастер спорта по самбо, 1 ДАН по дзюдо.<br/>
                    Выпускник ГБОУ ЦО «Самбо-70».<br/>
                    Образование: высшее ( РГУНГ им. И.М. Губкина; РАНХиГС; БГУ им. И.Г. Петровского) <br/>
                    Опыт работы: 9 лет<br/>
                    Эксперт аттестационной комиссии Федерации дзюдо России.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-feature mb-5 mb-lg-0">
                <div className="feature-img">
                  <img src={require("../assets/images/coaches2.jpg")} alt="Тренер Корочанский Данила Дмитриевич"/>
                </div>
                <div className="feature-footer text-center">
                  <h5>Корочанский Данила Дмитриевич</h5>
                  <p>
                    Тренер по самбо и дзюдо<br/>
                    Мастер спорта по самбо <br/>
                    Выпускник ГБОУ ЦО «Самбо-70».<br/>
                    Образование: высшее (РУДН), в процессе РГУФК(1 курс магистратура)<br/>
                    Опыт работы: 5 лет</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>


      <section className="coaches-area section-padding" id="reviews">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-top text-center">
                <h2>Отзывы</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">

            <div style={{width: '500px', height: '800px', overflow: 'hidden', position: 'relative', margin: '5px'}}>
              <iframe style={{
                width: '100%',
                height: '100%',
                border: '1px solid #e6e6e6',
                borderRadius: '8px',
                boxSizing: 'border-box'
              }} src="https://yandex.ru/maps-reviews-widget/95065366550?comments" title="reviews1"/>
              <a href="https://yandex.ru/maps/org/shkola_borby_superstar/95065366550/" rel="noreferrer" target="_blank" style={{
                boxSizing: 'border-box',
                textDecoration: 'none',
                color: '#b3b3b3',
                fontSize: '10px',
                fontFamily: 'YS Text,sans-serif',
                padding: '0 16px',
                position: 'absolute',
                bottom: '8px',
                width: '100%',
                textAlign: 'center',
                left: 0,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'block',
                maxHeight: '14px',
                whiteSpace: 'nowrap'
              }}>Школа борьбы Superstar на карте Москвы — Яндекс&nbsp;Карты</a></div>


            <div style={{width: '500px', height: '800px', overflow: 'hidden', position: 'relative', margin: '5px'}}>
              <iframe style={{
                width: '100%',
                height: '100%',
                border: '1px solid #e6e6e6',
                borderRadius: '8px',
                boxSizing: 'border-box'
              }} src="https://yandex.ru/maps-reviews-widget/94376498247?comments" title="reviews2"/>
              <a href="https://yandex.ru/maps/org/shkola_borby_superstar/94376498247/"  rel="noreferrer" target="_blank" style={{
                boxSizing: 'border-box',
                textDecoration: 'none',
                color: '#b3b3b3',
                fontSize: '10px',
                fontFamily: 'YS Text,sans-serif',
                padding: '0 16px',
                position: 'absolute',
                bottom: '8px',
                width: '100%',
                textAlign: 'center',
                left: 0,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'block',
                maxHeight: '14px',
                whiteSpace: 'nowrap'
              }}>Школа борьбы Superstar на карте Москвы — Яндекс&nbsp;Карты</a></div>


          </div>
        </div>
      </section>


    </MainLayout>
  );
}

export default MainPage;